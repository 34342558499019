export const pointStatus = [
    {
        value: 'WITHDRAWAL_REQUEST',
        name: 'withdrawal_re'
    },
    {
        value: 'WITHDRAWCANCEL',
        name: 'withdrawal_cancle'
    },
    {
        value: 'BID_DIGITAL',
        name: 'point_history_code'
    },
    {
        value: 'BID_REAL',
        name: 'point_history_code2'
    },
    {
        value: 'BIDFAIL',
        name: 'point_history_code3'
    },
    {
        value: 'AUCTION_COMPLETION',
        name: 'point_history_code4'
    },
    {
        value: 'PRICETAG_DIGITAL',
        name: 'point_history_code5'
    },
    {
        value: 'PRICETAG_REAL',
        name: 'point_history_code6'
    },
    {
        value: 'PRICETAG',
        name: 'point_history_code7'
    },
    {
        value: 'REALFAIL',
        name: 'point_history_code8'
    },
    {
        value: 'LICENSE_PURCHASE',
        name: 'point_history_code9'
    },
    {
        value: 'LICENSE_SALE',
        name: 'point_history_code10'
    },
    {
        value: 'CURATION_INCENTIVE',
        name: 'point_history_code11'
    },
    {
        value: 'COMPETITION_HOST',
        name: 'point_history_code12'
    },
    {
        value: 'COMPETITION_CANCEL',
        name: 'point_history_code13'
    },
    {
        value: 'COMPETITION_AWARD',
        name: 'point_history_code14'
    },
    {
        value: 'ADMINPLUS',
        name: 'point_history_code15'
    },
    {
        value: 'ADMINMINUS',
        name: 'point_history_code16'
    },
    {
        value: 'DETECT',
        name: 'point_history_code17'
    },
    {
        value: 'DETECT_FAIL',
        name: 'point_history_code18'
    },
    {
        value: 'CHARGE_CANCEL',
        name: 'point_history_code19'
    },
    {
        value: 'CHARGE',
        name: 'point_history_code20'
    },
    {
        value: 'JOIN',
        name: 'point_history_code21'
    },
    {
        value: 'RECOMMAND',
        name: 'point_history_code22'
    },
    {
        value: 'GIFT',
        name: 'item_gift'
    },
    {
        value: 'ROYALTY',
        name: 'item_royalty'
    },

];