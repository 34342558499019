<template>
   <!--    포인트 사용내역 레이아웃-->
   <div class="point_history_wrap">
     <!--    레이아웃-->
     <table class="point_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
            style = "table-layout: auto; width: 1200px; table-layout: fixed;">
       <colgroup>
         <col width="94px" />
         <col width="180px" />
         <col width="145px" />
         <col width="280px" />
         <col width="150px" />
         <col width="150px" />
         <col width="175px" />
       </colgroup>
       <tr class="title_row">
         <th colspan="1">No</th>
         <th colspan="1">{{$t('payment_number')}}</th>
         <th colspan="1">{{$t('status')}}</th>
         <th colspan="1">{{$t('content')}}</th>
         <th colspan="1">{{$t('use')}}{{$t('mingle')}}</th>
         <th colspan="1">{{$t('now')}}{{$t('mingle')}}</th>
         <th colspan="1">{{$t('date')}}</th>
       </tr>
       <tr class="t_content" v-for="(data,index) in pointData" :key="`point${index}`">
         <td>{{ tableIdx(index)}}</td>
         <td>{{ data.p_uuid ? data.p_uuid : '-'}}</td>
         <td>{{ $t(returnPointType(data.pd_code)) }}</td>
         <td v-html="$t(data.pd_code,data.ExtraData[0])"></td>
         <td>{{ returnPrice(data.p_point) }}</td>
         <td>{{ returnPrice(data.p_point_history + data.p_point)}}</td>
         <td class="cursor" v-html="returnDateFormat(data.created_at, 'YYYY-MM-DD<br>HH:mm')"></td>
       </tr>
     </table>
</div>
</template>

<script>
  import {pointStatus} from "@/service/pointService";
  import alertMixins from "@/mixins/alertMixins";
  import util from "@/mixins/util";
  import dateMixins from "@/mixins/dateMixins";
  import EventBus from "@/utils/event-bus";
  export default {
    name: "PointListHistoryLayout",
    mixins: [alertMixins, dateMixins],
    components: {},
    inject: [],
    provide() {
      return {}
    },
    props: {
      pointData: {default: () => []},
      paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
      return {
      }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
      movePage(url) {
        this.$router.push(`${this.$route.path}/${url}`)
      },
      tableIdx(index) {
        return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
      },
      returnPointType(status){
        let index = pointStatus.findIndex(ds => ds.value === status);
        if(index > -1) {
          return pointStatus[index].name;
        }
        return '-';
      },
      returnPrice(price){
        return util.Number.numFormat(price);
      }
    },
    watch: {},
  }
</script>

<style scoped>

</style>